<template>
  <div>
    <p>
      HOME BACKOFFICE - Página en contrucción
    </p>
  </div>
</template>
<script>
/**
 * Component to represent the home page of the backoffice.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 11:02 p. m.
 */
export default {
  name: 'HomeBackoffice'
}
</script>
<style lang="scss" scoped>
</style>
